@import "../../global";

:global(.react-component) {
  .icon {
    font-size: 24px;
    color: $light-green;
    padding-right: 5px;
  }

  .button {
    border: none;
    background: none;
    margin-left: 4px;
    padding-left: 0;
    color: #fff;
    font-size: 0.83rem;
    margin-top: 30px;
  }
}
