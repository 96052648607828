@import "src/global";

:global(.react-component) {
  .noBorder {
    border: none;
    background: none;
    width: 33.333%;
  }

  .caption {
    margin: 0;
  }

  .icon {
    color: $light-green;
  }
}
